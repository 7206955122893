



























import { Component, Vue } from 'vue-property-decorator';
import { IAuditTrailDto } from '@/interfaces/dto/IAuditTrailDto';
import dayjs from 'dayjs';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import auditAPI from '@/api/auditApi';


@Component({
    components: {
        LoadingOverlay,
    },
})

export default class ReleaseAudit extends Vue {

    created (): void {
        this.fetchData();
    }

    public dayJs = dayjs;

    public isFetchingData = false;

    public auditData: ReadonlyArray<IAuditTrailDto> = []

    private async fetchData (): Promise<void> {
        this.isFetchingData = true;
        if (this.$store.state.selectedScheme) {
            try {
                this.auditData = await auditAPI.getAuditTrail(this.$store.state.selectedScheme.clientEntityID);
            } catch (error) {
                this.$store.dispatch('pushNetworkErrorMessage', 'Failed to fetch audit trail data. Please try again later.');
            } finally {
                this.isFetchingData = false;
            }
        } else {
            this.$router.push('/');
        }
    }
}
