var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "manager--content" }, [
    _c(
      "div",
      { staticClass: "bg-grey h-100 w-100" },
      [
        _vm.isFetchingData
          ? _c("LoadingOverlay")
          : _c("div", { staticClass: "h-100" }, [
              _vm.auditData.length
                ? _c("table", [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Date")]),
                        _c("th", [_vm._v("User")]),
                        _c("th", [_vm._v("Comment")]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.auditData, function (audit, index) {
                        return _c(
                          "tr",
                          { key: index + "-" + audit.datePushed },
                          [
                            _c("td", { staticClass: "no-wrap" }, [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .dayJs(audit.datePushed)
                                    .format("DD MMM YYYY, HH:mm")
                                )
                              ),
                            ]),
                            _c("td", [_vm._v(_vm._s(audit.user))]),
                            _c("td", [_vm._v(_vm._s(audit.comment))]),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _c("p", { staticClass: "center" }, [
                    _vm._v("No releases found."),
                  ]),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }